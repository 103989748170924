import React, { useEffect, useState } from 'react';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import Lightbox from 'react-image-lightbox';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import Box from '@mui/material/Box';
import ImageList from '@mui/material/ImageList';
import ImageListItem from '@mui/material/ImageListItem';
import Button from '@mui/material/Button';

import Grid from '@mui/material/Grid';
import Main from 'layouts/Main';

import { Typography } from '@mui/material';
import Container from 'components/Container';
import ClassAssembly2024 from '../ClassAssembly2024';
import Archive2022 from '../ClassAssembly2023/Archive2022';

const ClassAssembly6A2024 = () => {
  const theme = useTheme();
  const [currentImage, setCurrentImage] = useState(0);
  const [viewerIsOpen, setViewerIsOpen] = useState(false);

  const openLightbox = (index) => {
    setCurrentImage(index);
    setViewerIsOpen(true);
  };

  const closeLightbox = () => {
    setCurrentImage(0);
    setViewerIsOpen(false);
  };

  const isMd = useMediaQuery(theme.breakpoints.up('md'), {
    defaultMatches: true,
  });


  const [config, setConfig] = useState({ base_image_url: '' });
  useEffect(() => {
    fetch('/config.json')  // For Gatsby, this will resolve to /config.json from the static folder
      .then(response => response.json())
      .then(data => setConfig(data))
      .catch(error => console.error('Error fetching config:', error));
  }, []);
  
  const p1 = `${config.base_image_url}/home/events-activities/class-assembly/6A/1.webp`;
  const p2 = `${config.base_image_url}/home/events-activities/class-assembly/6A/2.webp`;
  const p3 = `${config.base_image_url}/home/events-activities/class-assembly/6A/3.webp`;
  const p4 = `${config.base_image_url}/home/events-activities/class-assembly/6A/4.webp`;
  const p5 = `${config.base_image_url}/home/events-activities/class-assembly/6A/5.webp`;
  const p6 = `${config.base_image_url}/home/events-activities/class-assembly/6A/6.webp`;
  const p7 = `${config.base_image_url}/home/events-activities/class-assembly/6A/7.webp`;
  const p8 = `${config.base_image_url}/home/events-activities/class-assembly/6A/8.webp`;
  const p9 = `${config.base_image_url}/home/events-activities/class-assembly/6A/9.webp`;
  
  const photos = [
    {
      src: p1,
      source: p1,
      rows: 1,
      cols: 1,
    },
    {
        src: p2,
        source: p2,
        rows: 1,
        cols: 1,
      },
    {
      src: p3,
      source:p3,
      rows: 1,
      cols: 1,
    },
   
    {
      src: p4,
      source: p4,
      rows: 1,
      cols: 1,
    },
    {
        src: p5,
        source: p5,
        rows: 1,
        cols: 1,
      },
      {
        src: p6,
        source: p6,
        rows: 1,
        cols: 1,
      },
    {
      src: p7,
      source:p7,
      rows: 1,
      cols: 1,
    },
   
    {
      src: p8,
      source: p8,
      rows: 1,
      cols: 1,
    },
    {
        src: p9,
        source: p9,
        rows: 1.5,
        cols: 1,
      },
  ];

  return (
    <Main>
        <Container>
    <Grid container spacing={4}>
            <Grid item xs={12} md={8}>
    <Box>
        
      <Box >
      <Typography variant='h4' align='center'>
      Body Shaming
        </Typography>
        <Typography
        fontSize = "x-small"
        ><br/><br/>
 Class: 6 A            Date: 30 & 31 January 2025

        </Typography>
        {/* <Typography
          variant={'h6'}
          color={'primary'}
          align={'center'}
          fontWeight={'normal'}
        ><br/>
   “Imagination is the beginning of creation. You imagine what you desire, you will what you imagine and at last,
   you create what you will"- George Bernard Shaw

<br/>
        </Typography> */}

        <br />
        <Typography variant={'subtitle1'} align={'justify'}>
        Students of class 6 A presented their assembly on the topic: Body Shaming on Thursday, 30 January
2025 and Friday, 31 January 2025.                                                   
        <br></br>
        The topic was introduced through a small skit highlighting how our casual words on physical appearance
offend people. Through a musical mime, students were sensitized to understand and respect others
feelings and take an active part to eradicate body shaming which is a scar to one’s mental health.
Students also presented their views on how to create a safe space for students and make them strong
enough to embrace their imperfections which makes them unique and special. Thereafter, students took
a pledge not to participate in body shaming and to spread dignity across the land.
<br></br>
The assembly was well appreciated for its content and presentation by the Principal and the audience.
<br></br>
        </Typography>
      </Box>
      <Typography
          variant={'h6'}
          color={'primary'}
          align={'center'}
          fontWeight={'normal'}
        ><br/>
   “Real change begins with self-love and self-acceptance.”

<br/>
        </Typography>

    <Box>
        <ImageList
          variant="quilted"
          cols={2}
          rowHeight={isMd ? 200 : 100}
          gap={isMd ? 16 : 4}
        >
          {photos.map((item, i) => (
            <ImageListItem key={i} cols={item.cols} rows={item.rows}>
              <LazyLoadImage
                height={'100%'}
                width={'100%'}
                src={item.src}
                alt="..."
                effect="blur"
                onClick={() => openLightbox(i)}
                style={{
                  objectFit: 'cover',
                  filter:
                    theme.palette.mode === 'dark' ? 'brightness(0.7)' : 'none',
                  cursor: 'poiner',
                  borderRadius: 8,
                }}
              /> 
            </ImageListItem>
          ))}
        </ImageList>
      </Box>
      {viewerIsOpen && (
        <Lightbox
          mainSrc={photos[currentImage].src}
          nextSrc={photos[(currentImage + 1) % photos.length].src}
          prevSrc={
            photos[(currentImage + photos.length - 1) % photos.length].src
          }
          onCloseRequest={() => closeLightbox()}
          onMovePrevRequest={() =>
            setCurrentImage((currentImage + photos.length - 1) % photos.length)
          }
          onMoveNextRequest={() =>
            setCurrentImage((currentImage + 1) % photos.length)
          }
          reactModalStyle={{ overlay: { zIndex: 1500 } }}
        />
      )}
    </Box>
    </Grid>
    <Grid item xs={12} md={4}>
             
             <Box marginBottom={4}>
               <ClassAssembly2024 />
             </Box>
             <Box marginBottom={4}>
               <Archive2022 />
             </Box>
           {/* <SidebarNewsletter /> */}
         </Grid>
    </Grid> 
    </Container>
    </Main>
   
  );
};

export default ClassAssembly6A2024;